import React from 'react';
import Layout from '../../layouts/contact/contact';
import Banner from '../../page-components/contact/banner/banner';

const Contact = () => {
  return (
    <Layout>
      <Banner
        who={'Us About the Agency Plan'}
        messages={[
          'Compare chatbots and skills across clients and platforms',
          'Improve product offerings',
          'Increase engagement, acquisition, and monetization',
          'Provide access to clients',
        ]}
      />
    </Layout>
  );
};

export default Contact;
